<!--
 * @Descripttion:顶部的tabs
 * @version:1.0.0
 * @Author: huchongyuan
 * @Date: 2021-03-19 11:36:58
 * @LastEditors: huchongyuan
 * @LastEditTime: 2021-03-19 14:17:05
-->
<template>
    <div class="tabsMenu">
        <Menu mode="horizontal" :theme="theme1" active-name="1">
            <MenuItem name="1">内容管理</MenuItem>
            <MenuItem name="2">用户管理</MenuItem>
            <MenuItem name="4">综合设置</MenuItem>
        </Menu>
    </div>
</template>
<script>
    export default {
        name:"Tabs",
        data () {
            return {
                theme1: 'light'
            }
        }
    }
</script>
